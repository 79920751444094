<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat dense>
          <v-toolbar-title>Edit Chart of Accounts</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-data-table
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    :headers="headers"
                    :items="chartData"
                    :search="search"
                  >
                    <template v-slot:item.AcctName="{ item }">
                      <v-btn :to="`/financials/chart-of-accounts-editing/${item.id}`" color="blue" text>{{item.AcctName}}</v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <snackbar ref="snackbar"></snackbar>
    </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    loading: false,
    chartData: [],
    headers: [
      { text: "Name", value: "AcctName" }
    ]
  }),
  methods: {
    getChartOfAccounts() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/chartofaccounts`)
        .then(res => {
          self.chartData = res.ResponseData;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {
    this.getChartOfAccounts();
  }
};
</script>